/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Select, Input, Table, Tooltip, DatePicker } from 'antd';
import { useParams } from 'react-router-dom';

import useOfertaAtiva from '~/actions/OfertaAtiva/useOfertaAtiva';
import useGrupoCorretoresDropdown from '~/actions/DropDown/useGrupoCorretores';
import ModalConfirm from '~/components/ModalConfirm';

import Page from '~/components/Page';
import HeaderFiltros from '~/components/HeaderFiltro';
import history from '~/services/history';
import EmptyBox from '~/components/EmptyBox';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Button from '~/shared/Button';
import { Icon } from 'semantic-ui-react';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

moment.locale('pt-BR');

const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;


export function rowView(row, identificador) {
  history.push({
    pathname: `/app/oferta/listaLeads`,
    state: { row, identificador },
  });
}

export function navToNovo(identificador) {
  history.push({
    pathname: `/app/oferta`,
    state: { identificador },
  });
}

function ConsultaOfertaAtiva() {
  const [current, setCurrent] = useState(1);
  const [pageSize] = useState(10);
  const [loadingDel, setLoadingDel] = useState(false);

  const [status, setStatus] = useState(1);
  const [nome, setNome] = useState();
  const [grupoCorretoresId, setGrupoCorretoresId] = useState();
  const [dateRange, setDateRange] = useState();
  const ref = useRef();
  const [actions, setActions] = useState([]);
  const { data: dataGrupoCorretores } = useGrupoCorretoresDropdown();
  const { isLoading, onLoad, data, quantidade } = useOfertaAtiva(
    current,
    pageSize,
    nome,
    grupoCorretoresId,
    status,
    dateRange
  );
  const [loadTable, setloadTable] = useState(false);
  const [propostaId, setPropostaId] = useState(0);
  const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
  const isEmpty = !isLoading && data.length <= 0 && !loadTable;

  const { identificador } = useParams();

  useEffect(() => {
    const { actions } = getActionsMenus(identificador);
    setActions(actions || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identificador]);


  function onFiltros() {
    setloadTable(true);
    const { status, nome, grupoCorretoresId, dateRange } = ref.current.getFieldsValue();
    setDateRange(dateRange);
    setStatus(status);
    setNome(nome);
    setGrupoCorretoresId(grupoCorretoresId);
    setTimeout(() => {
      setloadTable(false);
    }, 1200);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  async function handleConfirmDelete() {
    if (propostaId) {
      setLoadingDel(true);

      await api
        .delete(`${url.OFERTA_ATIVA.DELETAR()}/${propostaId}`)
        .then(() => {
          setLoadingDel(false);
          onLoad();
          setOpenModalDeleteConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch(() => {
          setLoadingDel(false);
          setOpenModalDeleteConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModalDelete() {
    setOpenModalDeleteConfirm(false);
  }

  function handleModalConfirm(id) {
    setOpenModalDeleteConfirm(true);
    setPropostaId(id);
  }

  const incluir = !!actions.find(
    (create) => create.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Equipe',
      render: (row) => {
        const takeOnly = 2;

        if (!row?.membros || row?.membros?.length === 0)
          return <div> - </div>;

        const onlyFirsts = row.membros.slice(0, takeOnly);
        const hasMore = Math.max(row.membros.length - takeOnly, 0);

        const others = row.membros.slice(takeOnly, row.membros.length);
        return (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              {onlyFirsts.map(({ nome, tipo }, index) => (
                <div key={index} style={{
                  border: tipo === 2 ? '1px dashed #1d1f8c' : '1px solid transparent',
                  padding: '2px',
                  borderRadius: '5px',
                }}>
                  {tipo === 2 && (<Icon name="group" color="blue" />)}
                  {tipo === 1 && (<Icon name="user" color="green" />)}
                  {nome}
                </div>
              ))}
              {hasMore > 0 && (
                <Tooltip
                  title={
                    <div style={{
                      minWidth: '200px',
                    }}>
                      {others.map(({ nome, tipo }, index) => (
                        <div
                          key={index}
                          style={{
                            border: tipo === 2 ? '1px dashed #1d1f8c' : '1px solid transparent',
                            padding: '2px',
                            borderRadius: '5px',
                          }}
                        >
                          {tipo === 2 && (<Icon name="group" color="blue" />)}
                          {tipo === 1 && (<Icon name="user" color="green" />)}
                          {nome}
                        </div>
                      ))}
                    </div>
                  }
                >
                  <span style={{ color: '#1d1f8c', fontWeight: 'bold', cursor: 'pointer' }}>
                    {` +${hasMore}`}
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
        );
      }
    },
    {
      title: 'Total Leads',
      dataIndex: 'quantidadeItens',
      key: 'quantidadeItens',
    },
    {
      title: 'Aguardando Contato',
      dataIndex: 'aguardandoAtendimento',
      key: 'aguardandoAtendimento',
    },
    {
      title: 'Reservados',
      dataIndex: 'reservados',
      key: 'reservados',
    },
    {
      title: 'Criação',
      render: (row) => (
        <span>{moment(row?.dataCriacao).format('DD/MM/YYYY - HH:mm')}</span>
      ),
    },
    {
      title: 'Visualizar',
      key: '',
      fixed: 'center',
      width: 80,
      render: (row) => (
        <Tooltip title="Ver Leads">
          <div className="p-1" key={row.index}>
            <i
              onClick={() => rowView(row, identificador)}
              className="ri-eye-fill linkTable"
              style={{
                fontSize: '26px', marginRight: '10px'
              }}></i>
          </div>
        </Tooltip>

      ),
    },
    {
      title: 'Baixar',
      key: '',
      fixed: 'center',
      width: 80,
      render: (row) => (
        <Tooltip title="Funcionalidade em contrução ...">
          <div className="p-1" key={row.index}>
            <i
              onClick={() => {
              }}
              className="ri-download-fill linkTable"
              style={{
                fontSize: '26px', marginRight: '10px',
                color: '#d4d4d5'
                // color: '#3F8C24'
              }}></i>
          </div>
        </Tooltip>

      ),
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'center',
      width: 80,
      render: (row) => (
        <Tooltip title="Excluir Registro">
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleModalConfirm(row.id)}
              className="ri-delete-bin-5-fill linkTable"
              style={{ fontSize: '26px', marginRight: '10px', color: '#E01D20' }}></i>
          </div>
        </Tooltip>
      ),
    }
  ];

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Controle de oferta ativa</h5>
                <p className="category">Manutenção de ofertas</p>
                {incluir === true ? (
                  <HeaderFiltros description="Nova Oferta" navToNovo={() => navToNovo(identificador)} />
                ) : null}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form initialValues={{ status: 1 }} ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nome">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <Form.Item
                              className="my-2"
                              name="grupoCorretoresId">
                              <Select allowClear placeholder=" Equipe">
                                {dataGrupoCorretores.map((item) => (
                                  <Option key={item.id} value={item.id}>
                                    {item.grupo}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="dateRange">
                              <RangePicker
                                locale={locale}
                                format={dateFormat}
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="search" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {loadTable && (
                  <Fragment>
                    <Row className="mt-4">
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                    </Row>
                  </Fragment>
                )}
                {!isEmpty && !isLoading && !loadTable && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={defaultPagination(current, quantidade, changePage)}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox
                    message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loadingDel}
              open={openModalDeleteConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModalDelete}
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaOfertaAtiva;
