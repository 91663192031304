/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Layout, Menu, Drawer, Button, Popover } from 'antd';
import * as Icons from '@ant-design/icons';

import logo from '~/assets/img/latitude-preto.png';
import { Image } from 'semantic-ui-react';
import history from '~/services/history';
import { authenticationService } from '~/services/authentication.service';

import MasterImg from '~/assets/img/gestor.png';
import { UserInfo, UserInfoContainer } from './styles';
import { useDeviceScreen } from '~/hooks/use-device-screen';
import EditUserSimple from '../EditUserSimple/EditUserSimple';
import useLocalStorage from '~/hooks/localstorage/useLocalStorage'
import ModalEmpresas from '../ModalEmpresas';
import api from '~/services/api';
import URL from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';
import jwtDecode from 'jwt-decode';


const { Header, Content, Sider } = Layout;

const CustomIcon = (type) => {
  let AntdIcon = Icons[type];
  if (AntdIcon === undefined) AntdIcon = Icons['ExclamationCircleOutlined'];

  return <AntdIcon />;
};

export function goMenuItem(props, url, identificador, urlExterna, novaAba) {
  if (urlExterna) {
    if (novaAba)
      window.open(urlExterna, '_blank',);
    else
      history.push(`/app/siteExterno/${encodeURIComponent(urlExterna)}`,);
  }
  else
    history.push(
      `${props.match.path}${url.replace(':identificador', identificador)}`,
    );
}

const LatitudeLayout = ({ menuItems, props, children }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [sideItems, setSideItems] = useState([]);
  const [ofertaAtivaSelecionada, setOfertaAtivaSelecionada]= useLocalStorage('OfertaAtivaSelecionada', null);
  const [openModalEmpresas, setOpenModalEmpresas] = useState(false);
  const listEmpresas = JSON.parse(localStorage.getItem('icaseEmpresas')) || [];
  const [loadingEmpresa, setIsLoadingEmpresa] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState();

  const nomeCompleto = localStorage.getItem('nomeCompleto');
  const companyData =
    JSON.parse(localStorage.getItem('latitudeCompanyData')) || [];
  const userData = JSON.parse(localStorage.getItem('icaseUser')) || [];
  const userCompany = companyData?.nome || '';
  const imagemPerfil =
    userData?.userPictureUrl && userData?.userPictureUrl !== ''
      ? userData?.userPictureUrl
      : MasterImg;

      function sair() {
        authenticationService.logout();
    
        if (ofertaAtivaSelecionada) {
          setOfertaAtivaSelecionada(null);
        }
      }

  useEffect(() => {
    let auxSideItems = [];

    auxSideItems.push({
      key: `sub2022`,
      icon: (
        <div
          onClick={() => goMenuItem(props, '/', null)}
          className="side-dashboard">
          {CustomIcon('DashboardFilled')}
        </div>
      ),
      label: <div onClick={() => goMenuItem(props, '/', null)}>Dashboard</div>,
    });

    const menuFiltered = menuItems.filter((x) => x.component !== 'Dashboard');
    menuFiltered.forEach((items, index) => {
      const key = String(index + 1);
      if (items.filhos && items?.filhos.length >= 1) {
        auxSideItems.push({
          key: `sub${key}`,
          icon: CustomIcon(items.icon || 'DashboardOutlined'),
          label: items.title,
          children: items.filhos.map((child, j) => {
            const subKey = index * 4 + j + 1;
            return {
              key: subKey,
              label: (
                <div
                  onClick={() =>
                    goMenuItem(props, child.url, child?.identificador)
                  }>
                  {child.title}
                </div>
              ),
            };
          }),
        });
      } else {
        auxSideItems.push({
          key: `sub${key}`,
          icon: (
            <div
              onClick={() =>
                goMenuItem(props, items.url, items?.identificador, items?.urlExterna, items?.novaAba)
              }>
              {CustomIcon(items.icon || 'DashboardOutlined')}
            </div>
          ),
          label: (
            <div
              onClick={() =>
                goMenuItem(props, items.url, items?.identificador, items?.urlExterna, items?.novaAba)
              }>
              {items.title}
            </div>
          ),
        });
      }
    });

    auxSideItems.push({
      key: `sub1989`,
      icon: <div onClick={() => sair()}>{CustomIcon('ImportOutlined')}</div>,
      label: <div onClick={() => sair()}>Sair</div>,
    });

    setSideItems(auxSideItems);
  }, [menuItems, props]);

  const RenderMenuItem = () => (
    <Menu
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      items={sideItems}
    />
  );

  const { isSmall, isLarge, isXLarge, isXxLarge } = useDeviceScreen();
  const [openAvatar, setOpenAvatar] = useState(false);


  const showSider = !isSmall || isLarge || isXLarge || isXxLarge;
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const hide = () => {
    setOpenAvatar(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpenAvatar(newOpen);
  };

  async function redirectLogin(user) {
    const {
      empresaId,
      jwt,
    } = user.data.data;


    const { token, refreshToken } = jwt;

    const { exp } = jwtDecode(token);
    localStorage.setItem('icaseToken', token);
    localStorage.setItem('icasetokenExpiration', exp);
    localStorage.setItem('icaseRefreshToken', JSON.stringify(refreshToken));
    localStorage.setItem('icaseEmpresaId', empresaId);
    localStorage.setItem('icaseUser', JSON.stringify(user.data.data));

    if (empresaId || selectedEmpresaId) {
      const searchEmpresaId = selectedEmpresaId ? selectedEmpresaId : empresaId;

      await api
        .get(URL.EMPRESAS.CONSULTA_BY_ID(searchEmpresaId))
        .then((res) => {
          localStorage.setItem('latitudeCompanyData', JSON.stringify(res.data));
        }).finally(() => {
          window.location.reload();
        });
    }
    else
    window.location.reload();
  }

  async function handleLoginEmpresa(payloadEmpresa) {
    setIsLoadingEmpresa(true);

    await api.post(URL.LOGIN.WEB_POR_EMPRESA(), payloadEmpresa)
      .then((user) => {
        if (user.data) {
          redirectLogin(user);
        } else {
          Notify(
            'error',
            pt.comum.atencao,
            'Não foi encontrado usuário com os dados informados!',
          );
          window.location.reload();
        }
      })
      .catch((err) => {
        const { errors } = err.response.data;
        if (errors) {
          for (const item in errors) {
            Notify('error', item ? item : pt.comum.atencao, errors[item]);
          }
        }
        window.location.reload();
      }).finally(() => {
        setOpenModalEmpresas(false);
        setIsLoadingEmpresa(false);
      });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!showSider && (
        <>
          <Drawer
            title="Menu"
            placement='left'
            visible={isDrawerVisible}
            onClick={() => setIsDrawerVisible(false)}
            onClose={() => setIsDrawerVisible(false)}>
            <RenderMenuItem />
          </Drawer>
        </>
      )}
      {showSider && (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}>
          <RenderMenuItem />
        </Sider>
      )}
      <Layout className="latitude-layout">
        <Header className="latitude-layout-header d-flex align-items-center justify-content-between w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              {!showSider && (
                <div style={{ marginRight: 50 }}>
                  <Button
                    className="menu-button"
                    type="text"
                    icon={<Icons.MenuOutlined />}
                    onClick={() => setIsDrawerVisible(true)}
                  />
                </div>
              )}

              <Image
                onClick={() => setCollapsed(!collapsed)}
                size="small"
                src={logo}
                className="div-click trigger"
              />
            </div>
            <UserInfoContainer>
              <Popover
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                content={<EditUserSimple modalOpen={openAvatar} voltar={hide} />}
                trigger="click"
                open={openAvatar}
                onOpenChange={handleOpenChange}
              >
                <Image
                  style={{ width: 40, height: 40, marginRight: 10, cursor: 'pointer', objectFit: 'cover' }}
                  src={imagemPerfil}
                  avatar
                />
              </Popover>
              <UserInfo style={{ color: '#414141' }}>
                <p style={{ marginBottom: 0, fontSize: 16 }}>{nomeCompleto}</p>
                <p style={{ fontSize: 12 }}>{userCompany}{listEmpresas && listEmpresas?.length > 0 && <Icons.SwapOutlined onClick={() => setOpenModalEmpresas(true)} style={{ paddingLeft: 5, color: 'red', cursor: 'pointer' }} />}</p>
              </UserInfo>
            </UserInfoContainer>
          </div>
        </Header>
        <Content className="latitude-layout-content">{children}</Content>
        <ModalEmpresas
          open={openModalEmpresas}
          close={() => setOpenModalEmpresas(false)}
          selectedEvent={
            (empresaId) => { setSelectedEmpresaId(empresaId) }
          }
          confirmEvent={(v) => handleLoginEmpresa(v)}
          listEmpresas={listEmpresas}
          loadingEmpresa={loadingEmpresa}
          empresaId={selectedEmpresaId}
          textTitle={'Selecione uma empresa para logar no sistema!'}
          type='changeCompany'
        />
      </Layout>
    </Layout>
  );
};

export default LatitudeLayout;
