import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Select, Input, DatePicker } from 'antd';
import { Icon } from 'semantic-ui-react';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';

import Button from '~/shared/Button';
import api from '~/services/api';
import url from '~/infra/urls';
import Notify from '~/shared/Notify';
import pt from '~/infra/resources/strings/pt';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
moment.locale('pt-BR');

const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
  VISUALIZAR: 'visualizar',
};

const dateFormat = 'DD/MM/YYYY';
const arrayAtendimentos = [
  {
    id: 1,
    title: 'Whatsapp',
    icon: <i className="fab fa-whatsapp-square fa-3x"></i>,
  },
  { id: 2, title: 'Ligação', icon: <i className="fas fa-phone fa-3x"></i> },
  { id: 3, title: 'Email', icon: <i className="fas fa-envelope fa-3x"></i> },
  { id: 4, title: 'Parceria', icon: <i className="fas fa-handshake fa-3x"></i> },
  {
    id: 5,
    title: 'Pessoal',
    icon: <i className="fa fa-hands-helping fa-3x"></i>,
  },
  { id: 6, title: 'Sistema', icon: <i className="fas fa-desktop fa-3x"></i> },
  {
    id: 7,
    title: 'Desistência',
    icon: <i className="fa fa-window-close-o fa-3x"></i>,
  },
  {
    id: 8,
    title: 'Reunião',
    icon: <i className="fa fa-handshake fa-3x"></i>,
  },
  {
    id: 9,
    title: 'SMS',
    icon: <i className="fa fa-comments-o fa-3x"></i>,
  },
  {
    id: 10,
    title: 'Nota',
    icon: <i className="fa fa-sticky-note-o fa-3x"></i>,
  },
  {
    id: 11,
    title: 'Apresentação',
    icon: <i className="fa fa-newspaper-o fa-3x"></i>,
  },
  {
    id: 12,
    title: 'Imóvel',
    icon: <i className="fa fa-home fa-3x"></i>,
  },
];

function RegistroAtendimento({
  modo,
  eventoId,
  refreshStatusAtendimento,
  fetchRelacionamentos,
  isChangeSituacaoAtendimento = false,
  onFiltros,
  closeModal,
  atendimentoId,
  celular,
  imovelId,
  statusEmAtendimento = false
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setRequiredMessage] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (eventoId) fetch();

    async function fetch() {
      setLoadingData(true);

      await api
        .get(url.ATENDIMENTO.CONSULTA_ATENDIMENTOS(eventoId))
        .then((res) => {
          setRequiredMessage(!!(Number(res?.data?.tipoAtendimentoEvento) === 2));

          form.setFieldsValue({
            tipoAtendimentoEvento: String(res?.data?.tipoAtendimentoEvento),
            dataContato: moment(res?.data?.dataContato, 'YYYY-MM-DD'),
            proximoContato: moment(res?.data?.proximoContato, 'YYYY-MM-DD'),
            descricao: res?.data?.descricao,
          });
          setLoadingData(false);
        })
        .catch(() => {
          setLoadingData(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventoId]);

  async function onSaveRegisterAtendimento() {
    setLoading(true);

    form
      .validateFields()
      .then(async (dados) => {
        const payload = {
          atendimentoId,
          tipoAtendimento: dados.tipoAtendimentoEvento,
          dataContato:
            moment(dados.dataContato, 'YYYY-MM-DDTHH:mm:ss')
              .utc(false)
              .set({ hour: 12, minute: 0, second: 0 }) || null,
          proximoContato:
            moment(dados.proximoContato, 'YYYY-MM-DDTHH:mm:ss')
              .utc(false)
              .set({ hour: 12, minute: 0, second: 0 }) || null,
          descricao: dados.descricao,
          imovelId,
          prioridade: 1,
          acaoTomada: null,
          resultado: null,
          statusEmAtendimento: statusEmAtendimento,
          dataVencimento:
            moment(dados.proximoContato, 'YYYY-MM-DDTHH:mm:ss')
              .utc(false)
              .set({ hour: 12, minute: 0, second: 0 }) || null,
        };

        await api
          .post(url.ATENDIMENTO.ATENDIMENTOS(), payload)
          .then(async () => {
            // WHATSAPP
            if (Number(dados.tipoAtendimentoEvento) === 2 && celular) {
              let a = document.createElement('a');
              a.target = '_blank';
              a.href = `https://wa.me/55${celular}?text=${dados.mensagem}`;
              a.click();
            }

            if (fetchRelacionamentos)
              await fetchRelacionamentos(atendimentoId, imovelId);

            if (refreshStatusAtendimento) refreshStatusAtendimento(2);

            if (isChangeSituacaoAtendimento && onFiltros) onFiltros();

            setLoading(false);
            form.resetFields();
            closeModal();
          })
          .catch((err) => {
            setLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      })
      .catch(() => {
        setLoading(false);
      });
  }

  function handletipoAtendimentoEvento(value) {
    setRequiredMessage(!!(Number(value) === 2));
  }

  return (
    <Fragment>
      {loadingData && (
        <Row className="mx-1 mt-4">
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
          <Col xs="12" md="4">
            <SkeletonTableBasic cardShadowless lineNumber={1} />
          </Col>
        </Row>
      )}
      {!loadingData && (
        <Fragment>
          <Form form={form}>
            <Row>
              <Col xs="12" md="5">
                <p className="heading-small text-muted mb-2">
                  <small>Tipo de Atendimento</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="tipoAtendimentoEvento"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar o tipo de atendimento',
                    },
                  ]}>
                  <Select
                    disabled={modo === MODO.VISUALIZAR}
                    allowClear
                    placeholder=" Tipo de atendimento"
                    onClear={() => setRequiredMessage(false)}
                    onChange={handletipoAtendimentoEvento}>
                    {arrayAtendimentos.map((tp) => (
                      <Option key={tp.id}>{tp.title}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>Data do Contato</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="dataContato"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a data do contato',
                    },
                  ]}>
                  <DatePicker
                    disabled={modo === MODO.VISUALIZAR}
                    locale={locale}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder=" Data do contato"
                  />
                </Form.Item>
              </Col>
              <Col xs="12" md="3">
                <p className="heading-small text-muted mb-2">
                  <small>Próximo Contato</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="proximoContato"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a data do próximo contato',
                    },
                  ]}>
                  <DatePicker
                    disabled={modo === MODO.VISUALIZAR}
                    locale={locale}
                    format={dateFormat}
                    style={{ width: '100%' }}
                    placeholder=" Próximo contato"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <p className="heading-small text-muted mb-2">
                  <small>Descrição do atendimento</small>
                </p>
                <Form.Item
                  className="my-2"
                  name="descricao"
                  rules={[
                    {
                      required: true,
                      message: 'Favor informar a descrição do atendimento',
                    },
                  ]}>
                  <Input.TextArea
                    disabled={modo === MODO.VISUALIZAR}
                    placeholder=" Descrição"
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                    maxLength={1000}
                    showCount
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row className="pt-3">
            {modo !== MODO.VISUALIZAR && (
              <Col xs="12" md="4">
                <Button
                  className="mt-1"
                  onClick={(event) => {
                    event.preventDefault();
                    onSaveRegisterAtendimento();
                  }}
                  icon
                  labelPosition="left"
                  size="medium"
                  fluid
                  color="violet"
                  isloading={loading.toString()}
                  loadtext="Enviando...">
                  <Icon name="send" />
                  Enviar
                </Button>
              </Col>
            )}
            <Col xs="12" md="4">
              <Button
                className="mt-1"
                onClick={(event) => {
                  event.preventDefault();
                  closeModal();
                }}
                icon
                labelPosition="left"
                size="medium"
                fluid>
                <Icon name="undo" />
                Voltar
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
}

export default RegistroAtendimento;
