/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select, DatePicker } from 'antd';
import { Icon } from 'semantic-ui-react';
import { mask, unMask } from 'remask';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';
import { checkDocumento } from '~/shared/checkDocumento';
import 'moment/locale/pt-br';
import url from '~/infra/urls';
import history from '~/services/history';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';

import UploadImage from '~/components/UploadImage';

import useEmpresa from '~/actions/Empresa/useEmpresa';
import usePerfil from '~/actions/Perfil/usePerfil';
import useTipoUsuario from '~/actions/TipoUsuario/useTipoUsuario';

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

let userId = '';
let userCompany = [];
let userPerfilId = undefined;

function Corretor(props) {
  const [modo, setModo] = useState(MODO.CADASTRAR);
  const [loadPage, setloadPage] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [fileLogo, setFileLogo] = useState(null);
  const [alterLogo, setAlterLogo] = useState(false);
  const [filiais, setFiliais] = useState([]);
  const [identificador, setIdentificador] = useState();
  const [dateString, setDateString] = useState();

  const { data: empresas } = useEmpresa(1, 9999, null, null);
  const { data: perfis } = usePerfil();
  const { data: tipoUsuarios } = useTipoUsuario();

  const [form] = Form.useForm();

  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const administrador = roles.find((role) => role === 'SystemAdmin');

  const empresaId = localStorage.getItem('icaseEmpresaId');
  const sessionUserCompany = JSON.parse(localStorage.getItem('latitudeCompanyData'));

  async function getPerfisUsuario(userId) {
    if (userId) {
      await api.get(url.USUARIO_PERFIL.CONSULTA_BY_USER(userId))
        .then(res => {
          userPerfilId = res.data[0]?.perfilId;
        })
        .catch(err => {
          const { error } = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', pt.comum.atencao, error[item]);
            }
          }
        });
    }
  }

  async function getCompany(userId) {
    if (userId) {
      await api
        .get(`/auth/obter-usuarios-acesso?usuarioId=${userId}`)
        .then((response) => {
          userCompany = [];
          if (response?.data?.data.length > 0) {
            response?.data?.data?.forEach((child) => {
              if (child?.id !== "00000000-0000-0000-0000-000000000000") {
                userCompany.push(child?.empresaId);
              }
            });
          }
        });
    }
  }

  useEffect(() => {
    const remap = [];
    const index = empresas.findIndex((item) => item.id === empresaId);

    if (index > -1) {
      if (empresas[index]?.filiais !== null) {
        remap.push({
          id: empresas[index].id,
          descricao: empresas[index].nome,
        });

        empresas[index].filiais.forEach((child) => {
          remap.push({
            id: child.id,
            descricao: child.nome,
          });
        });

        setFiliais(remap);
      }
    }

    async function getData() {
      if (props.match.params) {
        if (props.match.params.modo === MODO.EDITAR) {
          const { user, identificador } = props.location.state;

          userId = user.usuarioId;

          setFileLogo({ url: user?.imagemUrl });
          setIdentificador(identificador);
          setModo(MODO.EDITAR);

          await getCompany(userId);
          await getPerfisUsuario(userId);

          setFileLogo({ url: user?.imagemUrl });
          setInitialValues({
            nome: user?.nomeCompleto || '',
            roleId: user?.roles[0]?.roleId,
            email: user?.email || '',
            cpf: mask(user?.cpf || '', [pattern.cpf]),
            celular: mask(user?.celular || '', [pattern.celular]),
            creci: user?.creci,
            perfilId: userPerfilId || undefined,
            nascimento: moment(user?.nascimento) || undefined,
            filiais: userCompany || [],
          });
        } else {
          const { identificador } = props.location.state;

          setIdentificador(identificador);
          setModo(MODO.CADASTRAR);
        }
      }

      setloadPage(false);
    }

    getData();

  }, [props.match.params, empresas]);

  function voltar() {
    if (identificador) {
      history.push(`/app/corretor/${identificador}`);
    } else {
      history.push('/app/corretor');
    }
  }

  function handleConfirm() {
    setOpenModalConfirm(false);
    voltar();
  }

  function onChangeDate(dateString) {
    return setDateString(dateString);
  }
  
  function onSave() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (modo === MODO.CADASTRAR) {
          const payload = {
            nome: dados?.nome,
            email: dados?.email,
            cpf: dados.cpf ? dados.cpf.replace(/\D/g, '') : dados?.cpf,
            empresaId: administrador ? dados?.empresaId : empresaId,
            celular: dados.celular,
            creci: dados.creci,
            imagem: fileLogo?.url,
            nascimento: moment(dateString, dateFormat),
            perfilId: dados.perfilId,
            roleId: dados?.roleId,
            filiaisId: dados.filiais,
          };

          await api
            .post(url.CORRETOR.CADASTRAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { errors } = err.response.data;
              if (errors) {
                for (const item in errors) {
                  Notify('error', pt.comum.atencao, errors[item]);
                }
              }
            });
        } else if (modo === MODO.EDITAR) {
          const payload = {
            id: userId,
            nome: dados?.nome,
            email: dados?.email,
            cpf: dados.cpf ? dados.cpf.replace(/\D/g, '') : dados?.cpf,
            celular: dados.celular,
            empresaId: administrador ? dados?.empresaId : empresaId,
            creci: dados.creci,
            imagem: alterLogo ? fileLogo?.url : null,
            roleId: dados?.roleId,
            nascimento: moment(dateString, dateFormat),
            perfilId: dados.perfilId,
            filiaisId: dados.filiais,
          };

          await api
            .put(url.CORRETOR.ATUALIZAR(), payload)
            .then((res) => {
              setIsLoading(false);
              setOpenModalConfirm(true);
            })
            .catch((err) => {
              setIsLoading(false);
              const { error } = err.response.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            });
        }
      })
      .catch((errorInfo) => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  function handleMask(e, type) {
    let value = e.currentTarget.value;
    value = unMask(value);
    value = mask(value, [type]);

    if (type === pattern.cpf) form.setFieldsValue({ cpf: value });
    if (type === pattern.celular) form.setFieldsValue({ celular: value });
  }

  return (
    <Fragment>
      {loadPage && <div>
        <CircularProgress />
        <SkeletonTableBasic />
      </div>}
      {!loadPage && <Row>
        <Col xs="12">
          <Card className="p-2">
            <CardHeader>
              <h5 className="title">
                {modo === MODO.CADASTRAR
                  ? 'Cadastrar usuário'
                  : 'Atualizar usuário'}
              </h5>
              <p className="category">Manutenção de usuário</p>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" md="3">
                  <UploadImage
                    fileLogo={fileLogo}
                    setFileLogo={setFileLogo}
                    setAlterLogo={setAlterLogo}
                    maxImageSize={5}
                    minWidth={354}
                    minHeight={472}
                  />
                </Col>
                <Col xs="12" md="9">
                  <Form
                    form={form}
                    initialValues={initialValues}
                    scrollToFirstError>
                    {administrador && (
                      <Row>
                        <Col xs="12">
                          <p className="heading-small text-muted mb-2 mx-2">
                            <small>Empresa</small>
                          </p>
                          <Form.Item
                            className="mx-2"
                            name="empresaId"
                            rules={[
                              {
                                required: true,
                                message: 'Favor informar o empresa!',
                              },
                            ]}>
                            <Select placeholder=" Empresa">
                              {empresas.map((item) => (
                                <Option key={item.id}>
                                  {item.nome}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Nome</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="nome"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o nome!',
                            },
                          ]}>
                          <Input placeholder=" Nome" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Usuário</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="roleId"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o tipo de usuário!',
                            },
                          ]}>
                          <Select
                            placeholder=" Tipo de Usuário"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                            }>
                            {tipoUsuarios.map((item) => (
                              <Option disabled={item?.disabled} key={item.id}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Email</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="email"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message:
                                'Favor inserir um e-mail válido',
                            },
                          ]}>
                          <Input placeholder=" Email" />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>CRECI</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="creci">
                          <Input placeholder=" CRECI" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 ml-2">
                          <small>Celular</small>
                        </p>
                        <Form.Item
                          className="ml-2"
                          name="celular"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informar o celular!',
                            },
                          ]}>
                          <Input placeholder=" Celular" onChange={(e) => handleMask(e, pattern.celular)} />
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2">
                          <small>CPF</small>
                        </p>
                        <Form.Item
                          className="mr-2"
                          name="cpf"
                          rules={[
                            {
                              required: true,
                              message: 'Favor informe o CPF',
                            },
                            // eslint-disable-next-line no-empty-pattern
                            ({ }) => ({
                              validator(rule, value) {
                                if (value) {
                                  const unMaskedValue =
                                    unMask(value);
                                  const isDocumento =
                                    checkDocumento(
                                      'cpf',
                                      unMaskedValue,
                                    );
                                  if (isDocumento === true) {
                                    return Promise.reject(
                                      'Número de CPF inválido',
                                    );
                                  }
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}>
                          <Input
                            placeholder=" Número do CPF"
                            onKeyUp={(e) =>
                              handleMask(e, pattern.cpf)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 mx-2">
                          <small>Perfil</small>
                        </p>
                        <Form.Item
                          className="mx-2"
                          name="perfilId">
                          <Select
                            placeholder=" Perfil"
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                            }>
                            {perfis.map((item) => (
                              <Option key={item.id}>
                                {item.descricao}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs="12" md="6">
                        <p className="heading-small text-muted mb-2 mx-2">
                          <small>Data de nascimento</small>
                        </p>
                        <Form.Item
                          className="my-2"
                          name="nascimento"
                        >
                          <DatePicker
                            locale={locale}
                            format={dateFormat}
                            onChange={onChangeDate}
                            style={{ width: '100%' }}
                            placeholder=" Data de nascimento"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    {!!!sessionUserCompany.matrizId && (
                      <Row>
                        <Col xs="12">
                          <p className="heading-small text-muted mb-2 mx-2">
                            <small>Filiais</small>
                          </p>
                          <Form.Item
                            className="mx-2"
                            name="filiais">
                            <Select
                              mode='multiple'
                              placeholder=" Filiais"
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                                  .indexOf(input.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
                              }>
                              {filiais.map((item) => (
                                <Option key={item.id}>
                                  {item.descricao}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Form>
                  {/*
                  <Tab title="Unidades" desc="Gerenciar Filial">
                    <Filial
                      setActiveTab={(value) => selectTab(value)}
                      userCompany={userCompany}
                      emailCorretor={emailCorretor}
                    />
                  </Tab> */}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs="12" md="3">
                  <Button
                    onClick={onSave}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    color="violet"
                    isloading={loading.toString()}
                    loadtext={'Cadastrando...'}>
                    <Icon name="check" />
                    Cadastrar
                  </Button>
                </Col>
                <Col xs="12" md="3">
                  <Button
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    onClick={() => voltar()}>
                    <Icon name="undo" />
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <ModalConfirm
            isDelete={false}
            icon="check circle"
            open={openModalConfirm}
            close={handleConfirm}
            message={
              modo === MODO.CADASTRAR
                ? 'Cadastrado com sucesso'
                : 'Alterações salvas'
            }
          />
        </Col>
      </Row>}
    </Fragment>
  );
}

export default Corretor;
